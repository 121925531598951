import {Pages}        from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import React          from "react";
import {FileUploader} from "../../modules/FileUploader";
import {Utils}        from "../Utils";

export class CancelEdit extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);
        this.initDateAndValidate();
    }

    initDateAndValidate() {
        let $reason = $("#reason"),
            $period = $("#period"),
            $dateStart = $("#date_start"),
            $dateEnd = $("#date_end"),
            minDate = Utils.getDateForDatepicker($dateStart.attr("data-date-min")),
            maxDate = Utils.getDateForDatepicker($dateStart.attr("data-date-max")),
            $endDateContainer = $("#end_date_container"),
            $labelStart = $("label[for='date_start']"),
            dateFormat = app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
            dateStart = Utils.getDateForDatepicker($dateStart),
            dateEnd = Utils.getDateForDatepicker($dateEnd),
            defaultDateStart = (dateStart === null) ? "0" : dateStart,
            defaultDateEnd = (dateEnd === null) ? "0" : dateEnd,
            from = $dateStart
                .datepicker({
                    dateFormat: dateFormat,
                    defaultDate: defaultDateStart,
                    changeMonth: true,
                    changeYear: true,
                    numberOfMonths: 3,
                    minDate: minDate,
                    maxDate: maxDate,
                    beforeShow: Utils.correctInputPosition,
                })
                .on("change", function () {
                    let date = Utils.getDateForDatepicker(this);
                    to.datepicker("option", "minDate", date);
                    to.datepicker("option", "defaultDate", date);
                }),
            to = $dateEnd.datepicker({
                dateFormat: dateFormat,
                defaultDate: defaultDateEnd,
                changeMonth: true,
                changeYear: true,
                numberOfMonths: 3,
                minDate: minDate,
                maxDate: maxDate,
                beforeShow: Utils.correctInputPosition,
            });

        $period.on("change", function (e) {
            $dateEnd.val("");
            if (this.checked) {
                $endDateContainer.show();
                $labelStart.html("Дата начала");
            } else {
                $endDateContainer.hide();
                $labelStart.html("Дата");
                $dateEnd.val($dateStart.val());
            }
        });

        // validate
        $("#submit_button").on("click touchstart", function (e) {

            if ($period.prop("checked") && $dateEnd.val() === "") {
                alert("Если выбран ввод даты за период, то необходимо заполнить дату окончания периода");
                return false;
            } else if ($dateStart.val() === "") {
                alert("Не установлена дата начала периода");
                return false;
            } else if ($reason.val() === "") {
                alert("Не заполнена причина отмены!");
                return false;
            }

        });

    }

}
